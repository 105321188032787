.App {
  width: 100vw;
  z-index: 0;
}

.backgroundOverlay {
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  z-index: 10;
}

.darkenOverlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  z-index: 99;
  background-color: rgba(0,0,0,0.6);
}

.gradientOverlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 50vw;
  background: linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0.2), rgba(255,255,255,0));
  z-index: 100;
}

.animationOverlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  background-color: #ffffff;
  z-index: 100000;
}

.logoContainer {
  height: 50vh;
  width: auto;
  position: fixed;
  top: 25vh;
  left: 20vw;
  z-index: 100001;
}

.logo {
  height: 100%;
  width: auto;
}

.sloganContainer {
  position: fixed;
  bottom: calc(3vh);
  left: calc(5vw - 5vh);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  z-index: 500;
}

.sloganContainer h1 {
  font-family: 'Lato', 'sans-serif';
  font-weight: 900;
  font-size: 3.5vw;
  margin: 0;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

.sloganContainer h3 {
  font-family: 'Lato', 'sans-serif';
  font-weight: 300;
  font-size: 2.3vw;
  margin: 0;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

.infoContainer {
  z-index: 500;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
}

.usContainer {
  background-color: rgba(0, 101, 126, 0.8);
}

.servicesContainer {
  background-color: rgba(144, 0, 67, 0.8);
}

.contactContainer {
  background-color: rgba(166, 165, 0, 0.8);
}

.clientsContainer {
  background-color: rgba(0, 101, 126, 0.8);
}

.infoContent {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  font-family: "Lato", "sans-serif";
}

.usContent p {
  font-size: 12px;
  line-height: 30px;
  font-weight: 300;
  color: #ffffff;
  letter-spacing: 2px;
  text-align: justify;
  margin-right: 2vw;
  margin-left: 2vw;
  margin-bottom: 3vh;
  text-shadow: 3px 3px 6px rgba(0,0,0,0.5); 
}

.usContent a {
  font-size: 12px;
  line-height: 30px;
  font-weight: 400;
  color: #ffffff;
  letter-spacing: 2px;
  align-self: flex-end;
  margin-right: 2vw;
  margin-bottom: 3vh;
  text-shadow: 3px 3px 6px rgba(0,0,0,0.5);
  text-decoration: none;
  cursor: pointer; 
}

.servicesContent p {
  align-self: flex-end;
  font-size: 12px;
  line-height: 20px;
  font-weight: 300;
  color: #ffffff;
  letter-spacing: 2px;
  text-align: right;
  margin-bottom: 1.5vh;
  margin-top: 1.5vh;
  margin-right: 2vw;
  margin-left: 2vw;
  text-shadow: 3px 3px 6px rgba(0,0,0,0.5); 
}

.contactContent {
  align-items: flex-end;
}

.contactContent h3 {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  letter-spacing: 2px;
  margin-right: 2vw;
  margin-bottom: 10px;
  text-shadow: 3px 3px 6px rgba(0,0,0,0.5); 
}

.contactContent p, a {
  font-size: 12px;
  font-weight: 300;
  line-height: 26px;
  text-align: right;
  color: #ffffff;
  letter-spacing: 2px;
  margin-right: 2vw;
  margin-top: 0;
  margin-bottom: 0;
  text-shadow: 3px 3px 6px rgba(0,0,0,0.5); 
  text-decoration: none;
}

.contactLastLine {
  margin-bottom: 3vh;
}

.clientsContent {
  justify-content: space-evenly;
}

.clientsLogo {
  width: 11vw;
  height: auto;
}

.navbar {
  position: fixed;
  top: 3vh;
  left: 28vw;
  width: 40vw;
  height: 11vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 500;
}

.navbar p {
  font-family: "Lato", "sans-serif";
  font-size: 11px;
  font-weight: 900;
  color: #ffffff;
  text-shadow: 3px 3px 6px rgba(0,0,0,0.5); 
  cursor: pointer;
}

.toggleIconContainer {
  display: none;
}

@media (max-width: 1024px) {
  .infoContent {
    width: 90%;
    margin: auto;
  }

  .toggleIconContainer {
    position: fixed; 
    top: 5vh;
    right: 3vh;
    height: 9vh;
    width: 5vh;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .toggleIcon {
    color: #ffffff;
    font-size: 5vh;
    cursor: pointer;
  }

  .clientsContent {
    height: 80%;
    margin-top: 18vh;
  }

  .clientsLogo {
    width: 20vh;
    height: auto;
  }
}